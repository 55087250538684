<template>
  <div class="page">
    <list-layout
      ref="employCompanyListRefs"
      :filter-form="form"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      :on-delete="removeListItem"
      :on-edit="editListItem"
      :onPutBack="onPutBack"
    >
      <template #top>
        <div style="margin-bottom: 20px">
          <el-button type="primary" size="small" @click="addListItem"
            >新建企业</el-button
          >
          <el-button type="primary" size="small" @click="isShowImportCompany = true"
            >批量导入企业</el-button
          >
        </div>
      </template>
      <template #filter="{pageData, idList }">
        <ListFilter
          :filterForm="form"
          :page="pageData.page"
          :uploadFilter="ok"
          :ids="idList"
        />
      </template>
    </list-layout>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <ImportCompany v-model="isShowImportCompany" :updateList="onRefresh" />
  </div>
</template>

<script>
import {
  getCompanyList,
  del,
  softDelete,
  putBack,
} from '@/modules/employ/api/employ-company-list'
import ListFilter from '@/modules/employ/components/employ-company-list/ListFilter.vue'
import ListLayout from '@/base/layout/ListLayout'
import Pagination from "@/base/components/Default/Pagination";
import ImportCompany from '@/modules/employ/components/employ-company-list/ImportCompany'
export default {
  components: {
    ListLayout,
    ListFilter,
    Pagination,
    ImportCompany,
  },

  data() {
    return {
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      form: {
        tab: 'normal', // normal 、deleted
        keyword: '',
        create_start_time: -1,
        create_end_time: -1,
        page: 1,
        page_size: 20,
      },
      // 弹窗不需要显示操作列，需要重新声明覆盖掉
      thead: [
        { type: 'selection' },
        { label: '企业logo', prop: 'logo', minWidth: 100 },
        { label: '企业名称', prop: 'name', minWidth: 100 },
        { label: '企业地址', prop: 'addresses_text', minWidth: 100 },
        { label: '企业邮箱', prop: 'email', minWidth: 100 },
        { label: '企业行业', prop: 'category', minWidth: 100 },
        { label: '企业规模', prop: 'scale_text', minWidth: 100 },
        { label: '融资情况', prop: 'financing_text', minWidth: 100 },
        { label: '创建时间', prop: 'create_time', minWidth: 180 },
        {
          label: '操作',
          type: 'operation',
          width: 120,
          operations: [
            {
              command: 'edit',
              text: '编辑',
              visible: (r, q) => q.tab !== 'deleted',
            },
            {
              command: 'putback',
              visible: (row, form) => form.tab === 'deleted',
            },
            { command: 'delete' },
          ],
        },
      ],
      pageData: {
        page_size: 15,
        tab: 'normal',
      },
      isShowImportCompany:false,
      selectArr:[]
    }
  },
  methods: {
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 数据删除
    removeListItem(idList, forever = false) {
      console.log(idList, forever)
      return forever ? del({ ids: idList }) : softDelete({ ids: idList })
    },
    /**
     * 编辑
     * @param{string} mode  弹窗还是抽屉显示
     */
    editListItem(row) {
      this.$router.push({
        name: 'EmployCompanyEdit',
        params: { id: row.id },
      })
    },
    addListItem() {
      this.$router.push({
        name: 'EmployCompanyAdd',
      })
    },
    //  还原
    onPutBack(idList) {
      return putBack({ ids: idList })
    },
    // 新增、编辑保存成功后刷新
    onRefresh() {
      this.$refs.employCompanyListRefs?.getList({ page: 1 })
    },

    // 筛选回调
    ok(e) {
      this.onRefresh()
    },
    // 拉取数据
    getList(query) {
      return getCompanyList(query)
    },
    changPage(e) {
      this.form.page_size = e.page_size
      const pageData = { ...this.form, ...e }
      this.getList(pageData)
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  right: 60px;
  top: 110px;
}
</style>
