<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="onExport"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="企业名称：" prop="keyword">
          <el-input
            v-model="form.keyword"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import {
  exportCompany
} from '@/modules/employ/api/employ-company-list'
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
    }
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },

    //筛选
    ok() {
      this.uploadFilter(this.form)
    },

    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      exportCompany(postData).catch((err) => {});
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
